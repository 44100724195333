import styled, { css } from 'styled-components'
import { green, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const styles = css`
  background: ${green[500]};
  border-radius: 8px;
  border: 1px solid #80BCBA;
  color: ${white};
  font-size: 14px;
  height: 48px;
  line-height: 18px;
  font-weight: 700;

  &:hover {
    opacity: 0.8;
    color: ${white};
  }

  @media ${device.desktopLG} {
    max-width: 456px;
  }
`

export const Button = styled.button`
  ${styles}
`

export const LinkButton = styled.a`
  ${styles}

  &.bg-none {
    color: ${green[500]};
    background: none;
  }
`

export const Span = styled.span`
  ${styles}
`
