import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Card = styled.div`
  background: white;
  padding: 30px 26px;
  border-radius: 8px;
  border: 1px solid ${grayscale[200]};
  min-height: 172px;

  @media ${device.tablet} {
    min-height: 220px;
  }
  @media ${device.desktopLG} {
    min-height: 181px;
  }
`
