
import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import SectionComponent from 'src/components/SectionComponent'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import ReinvistaSeuCashbackJSON from './../../assets/data/data-invest.json'

import { ContentType, ItemProps } from './types'
import { ContainerReinvista } from './style'

type ModalProps = {
  setIsOpen?: Function;
}

const InvestFacil = ({ setIsOpen }: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()
  const urlAppMobile = 'https://intergo.app/4ba27c6a'
  const { title, description, cta, cards }: ContentType = ReinvistaSeuCashbackJSON

  const HandleClick = () => {
    width < WIDTH_MD
      ? window.location.href = `${urlAppMobile}`
      : setIsOpen && setIsOpen(true)
  }

  return (
    <SectionComponent
      id='reinvista-o-seu-cashback'
      SectionStyles='py-5 bg-white'
      RowStyles='align-items-center justify-content-between'
      minHeight={{ sm: '611px', md: '412px', lg: '524px', xl: '524px' }}
    >
      <div className='col-12 col-md-6 col-xl-5 pr-lg-0'>
        <h2
          className='font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className='fs-14 lh-17 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 text-grayscale--500 mb-0 mb-md-3 pr-md-4'
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {width >= WIDTH_MD &&
          <button
            title={cta.text}
            className='btn bg-green-100 text-white btn--lg fw-600 text-none mt-2'
            dangerouslySetInnerHTML={{ __html: cta.text }}
            onClick={() => {
              HandleClick()
              sendDatalayerEvent({
                section: 'dobra_03',
                section_name: title,
                element_action: 'click button',
                element_name: cta.text,
              })
            }}
          />
        }
      </div>
      <ContainerReinvista className='col-12 col-md-6 col-xl-7'>
        <DefaultCarousel
          sm={{ items: 1 }}
          md={{ items: 1, partialVisibilityGutter: 15 }}
          lg={{ items: 1, partialVisibilityGutter: 73 }}
          xl={{ items: 1, partialVisibilityGutter: 265 }}
        >
          {cards.map((item: ItemProps, index: number) => (
            <div
              key={index}
              title={item.title}
              className='card-carousel mr-4 d-flex flex-column'
            >
              <div className='mb-5'><OrangeIcon size='MD' color='#07605B' icon={item.icon} /></div>
              <span
                className='font-sora fs-20 lh-25 text-grayscale--500 fw-600 mb-2'
                dangerouslySetInnerHTML={ { __html: item.title } }
              />
              <p className='fs-16 lh-20 text-grayscale--500'>{item.description}</p>
            </div>
            ),
          )}
        </DefaultCarousel>
        {width < WIDTH_MD &&
          <a
            href={urlAppMobile}
            title={cta.text}
            className='btn bg-green-100 text-white btn--lg fw-600 text-none mt-4'
            dangerouslySetInnerHTML={{ __html: cta.text }}
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_03',
                section_name: title,
                element_action: 'click button',
                element_name: cta.text,
                redirect_url: urlAppMobile,
              })
            }}
          />
        }
      </ContainerReinvista>
    </SectionComponent>
  )
}

export default InvestFacil
