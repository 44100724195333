import React from 'react'
import Img from 'gatsby-image'
import SectionComponent from 'src/components/SectionComponent'
import ScrollTo from 'src/components/ScrollTo'
import useWidth from 'src/hooks/window/useWidth'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import usePageQuery from '../../pageQuery'
import CardVantagens from './card-vantagens.json'
import { VantagensProps } from '../../types'
import { WIDTH_MD } from 'src/utils/breakpoints'
import { Span } from '../../styles'

const ConhecaAsVantagens = () => {
  const data = usePageQuery()
  const width = useWidth(300)

  return (
    <SectionComponent
      id='conheca-as-vantagens'
      minHeight={{ sm: '846px', md: '510px', lg: '578px', xl: '753px', xxl: '510px' }}
    >
      <div className='col-12 col-md-6 col-lg-5 mb-4'>
        { width <= WIDTH_MD
          ? <Img fluid={data.heroEmpreendedorSorridenteMobileTablet.fluid} alt='Empreendedor sorridente ao analisar seus investimentos empresariais pelo Super App Inter Empresas.' />
          : <Img fluid={data.heroEmpreendedorSorridenteDesktop.fluid} alt='Empreendedor sorridente ao analisar seus investimentos empresariais pelo Super App Inter Empresas.' />
        }
      </div>
      <div className='col-12 col-md-6 col-xl-5 pl-xl-0'>
        <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 font-sora text-grayscale--500'>
          Conheça as vantagens de investimentos para sua empresa
        </h2>
        {CardVantagens.map((item: VantagensProps, key: number) => (
          <div className='d-flex mt-4' key={key}>
            <div className='col-2 pl-md-0 col-md-1'>
              <OrangeIcon icon={item.icon} size='MD' color='#07605B' />
            </div>
            <div className='col-10 col-xl-11 pl-0 pl-md-1 px-lg-0'>
              <h3 className='font-sora fs-16 lh-20 fs-lg-18 lh-lg-22 fs-xl-20 lh-xl-25 text-grayscale--500 fw-600 mb-2'>{item.title}</h3>
              <p className='fs-14 lh-17 fs-xl-16 lh-xl-19 text-grayscale--400 fw-400 mb-0'>{item.description}</p>
            </div>
          </div>
          ))
        }
        <ScrollTo
          to='#como-investir'
          section='dobra_03'
          sectionName='Conheça as vantagens de investir o dinheiro da sua empresa'
          elementName='Saiba como investir'
          title='Saiba como investir'
        >
          <Span className='btn text-none mt-4 mt-md-5'>
            Saiba como investir
          </Span>
        </ScrollTo>
      </div>
    </SectionComponent>
  )
}

export default ConhecaAsVantagens
