import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const BlogCards = styled.div`

  span.tag { 
    font-size: 12px;
    line-height: 15px;
    width: 142px;
    background: ${orange.extra};
    border-radius: 4px;
    height: 16px;
    padding: 2px 5px;
  }

  .gatsby-image-wrapper {

    @media ${device.desktopXXL} { 
      max-width: 100% !important;
    }
  }

  .content {
    border-radius: 16px 24px 0 0;
    
    @media ${device.tablet} { 
      top: 31px;
      height: 210px;
    }
    @media ${device.desktopLG} { 
      height: 240px;
    }
  }

  &.p-card {
    padding: 0;
    
    &:last-child {
      padding-right: 0;
    }

    @media ${device.tablet} { 
      padding-right: 24px;
    }
    @media ${device.desktopLG} { 
      padding-right: 10.5px;
      padding-left: 10.5px
    }     
  }

  article {
    display: grid;
    min-height: 350px;

    @media ${device.desktopLG} { 
      min-height: 470px;
    }
  }
`
