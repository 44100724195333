import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import Investments from '@interco/icons/build-v4/orangeds/SM/investments'

import CardData from './card-data-melhores-opcoes.json'
import { Card } from './styles'

import { MelhoresOpcoesProps } from '../../types'

const MelhoresOpcoes = () => {
  return (
    <SectionComponent
      id='as-melhores-opcoes'
      SectionStyles='bg-grayscale--100'
      RowStyles='justify-content-md-start align-items-md-start'
      minHeight={{ sm: '795px', md: '376px', lg: '408px', xl: '509px', xxl: '509px' }}
    >
      <div className='col-12'>
        <div className='col-xl-9 mx-xl-auto text-center'>
          <h2 className='font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'>
            Os melhores investimentos para PJ
          </h2>
          <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--400 mt-4 mb-3'>
            Conheça alguns tipos de investimentos disponíveis para empresas no Super App do Inter.
          </p>
        </div>
      </div>
      {CardData.map((item: MelhoresOpcoesProps, key: number) => (
        <div className='mt-3 col-12 col-md-4' key={key}>
          <Card>
            <Investments height={32} width={32} color='#6A6C72' />
            <h3 className='font-sora fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600 my-3'>{item.title}</h3>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20  text-grayscale--400 fw-400 mb-0'>{item.description}</p>
          </Card>
        </div>
      ))}
    </SectionComponent>
  )
}

export default MelhoresOpcoes
