import React from 'react'
import Img from 'gatsby-image'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import SectionComponent from 'src/components/SectionComponent'
import HowTo from 'src/components/StructuredData/HowTo'
import useWidth from 'src/hooks/window/useWidth'
import pageContext from '../../pageContext.json'
import ComoInvestirNoInterEmpresas from './carousel-como-investir-inter-empresas.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { WIDTH_MD } from 'src/utils/breakpoints'

import usePageQuery from '../../pageQuery'
import { ModalProps, StepProps } from '../../types'

import { Container } from './styles'
import { Button } from '../../styles'

const OmelhorAppDeInvestimentos = ({ setIsOpen }: ModalProps) => {
  const data = usePageQuery()
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  const howToData = pageContext.structuredData.howTo
  const url = 'https://interempresas.go.link/investimentos?adj_t=6htgrz3&adj_campaign=Landingpageinvestimentoshome&adj_adgroup=InvestimentosPJ&adj_creative=Qr code&adj_label=Investimentos PJ&adj_redirect=https%3A%2F%2Finter.co%2Fempresas%2Fconta-digital%2Fpessoa-juridica%2F&adj_redirect_macos=https%3A%2F%2Finter.co%2Fempresas%2Fconta-digital%2Fpessoa-juridica%2F'

  const HandleClick = () => {
    width < WIDTH_MD
    ? window.location.href = `${url}`
    : setIsOpen && setIsOpen(true)
  }

  return (
    <SectionComponent
      id='como-investir'
      SectionStyles='bg-grayscale--100'
      ContainerStyles='py-lg-4'
      minHeight={{ sm: '830px', md: '777px', lg: '980px', xl: '1020px', xxl: '1128px' }}
    >
      <Container className='col-12 text-md-center'>
        <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 font-sora text-grayscale--500 mb-3'>
          O melhor app de investimento para empresas
        </h2>
        <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-0'>
          Você pode realizar investimentos para PJ através do Super App ou do Internet Banking do Inter.
        </p>
        <HowTo data={howToData}>
          <DefaultCarousel
            sm={{ items: 1 }}
            md={{ items: 3 }}
            lg={{ items: 3 }}
            xl={{ items: 3 }}
          >
            {ComoInvestirNoInterEmpresas.map((item: StepProps, index: number) => (
              <div key={index}>
                <div className='row justify-content-start justify-content-md-center'>
                  <div className='col-12 d-flex flex-md-column mb-2 text-md-center align-items-md-center'>
                    <span className='text-grayscale--500 step'> {item.step}</span> <span className='px-1 d-md-none'> - </span>
                    <h3 className='text-grayscale--500 mb-4 pb-2 px-md-2 px-lg-4' dangerouslySetInnerHTML={{ __html: item.title }} />
                  </div>
                  <div className='col-12 col-md-9 col-lg-10 d-flex justify-content-center mb-5 mb-md-0 mb-lg-3 px-md-1 px-lg-0'>
                    { width < WIDTH_MD
                      ? <Img
                          fluid={data[item.imageMobile].fluid}
                          alt='Tela de um celular mostrando como fazer investimentos empresariais no Super App Inter Empresas.'
                        />
                    : <Img
                        fluid={data[item.imageDesktop].fluid}
                        alt='Tela de um celular mostrando como fazer investimentos empresariais no Super App Inter Empresas.'
                      />
                  }
                  </div>
                </div>
              </div>
              ),
            )}
          </DefaultCarousel>
        </HowTo>
        <div className='d-flex justify-content-center'>
          <Button
            onClick={() => {
              HandleClick()
              sendDatalayerEvent({
                section: 'dobra_06',
                section_name: 'Como sua empresa pode investir no Inter Empresas',
                element_action: 'click button',
                element_name: 'Investir',
              })
            }}
            title='Investir'
            className='btn mt-4 mt-md-5 text-none cta'
          >
            Quero Investir
          </Button>
        </div>
      </Container>
    </SectionComponent>
  )
}

export default OmelhorAppDeInvestimentos
