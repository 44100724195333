import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      heroEmpreendedoraFelizMobileTablet: imageSharp(fluid: {originalName: { regex: "/hero-empreendedora-feliz-mobile-tablet/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      heroEmpreendedoraFelizDesktop: imageSharp(fluid: {originalName: { regex: "/hero-empreendedora-feliz-desktop/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      heroEmpreendedorSorridenteMobileTablet: imageSharp(fluid: {originalName: { regex: "/hero-empreendedor-sorridente-mobile-tablet/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      heroEmpreendedorSorridenteDesktop: imageSharp(fluid: {originalName: { regex: "/hero-empreendedor-sorridente-desktop/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      stepOneMobileInvestimentosEmpresariais: imageSharp(fluid: {originalName: { regex: "/step-01-mobile-investimentos-empresariais/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      stepTwoMobileInvestimentosEmpresariais: imageSharp(fluid: {originalName: { regex: "/step-02-mobile-investimentos-empresariais/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      stepThreeMobileInvestimentosEmpresariais: imageSharp(fluid: {originalName: { regex: "/step-03-mobile-investimentos-empresariais/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      stepOneInvestimentosEmpresariais: imageSharp(fluid: {originalName: { regex: "/step-01-investimentos-empresariais/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      stepTwoInvestimentosEmpresariais: imageSharp(fluid: {originalName: { regex: "/step-02-investimentos-empresariais/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      stepThreeInvestimentosEmpresariais: imageSharp(fluid: {originalName: { regex: "/step-03-investimentos-empresariais/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      valorizeSeuNegocioInvestimentosParaEmpresas: imageSharp(fluid: {originalName: { regex: "/valorize-seu-negocio-investimentos-para-empresas/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      guiaDoCdbParaEmpresas: imageSharp(fluid: {originalName: { regex: "/guia-do-cdb-para-empresas/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      fundosDeInvestimentosParaEmpresas: imageSharp(fluid: {originalName: { regex: "/fundos-de-investimentos-para-empresas/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
