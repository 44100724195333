import styled from 'styled-components'
import { green, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Container = styled.div`

  .react-multi-carousel-track {
    padding-bottom: 0;

    h3 {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;

      @media ${device.tablet} {
        min-height: 40px;
        font-weight: 400;
      }
    }

    span {
      font-weight: 700;

      @media ${device.tablet} {
        font-weight: 400;

        &.step {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          background: ${green[500]};
          align-items: center;
          justify-content: center;
          display: flex;
          color: ${white};
          font-weight: 700;
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 12px;
        }
      }

      @media ${device.desktopLG} {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .react-multiple-carousel__arrow {
    svg {
      fill: ${green[500]}
    }
  }

  .react-multi-carousel-dot-list {

    .react-multi-carousel-dot {
      button {
        background-color: ${green[500]};
      }
    }
  }

  button {

    @media ${device.tablet} {
      &.cta {
        max-width: 456px;
      }
    }
  }
`
