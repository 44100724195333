import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, green } from 'src/styles/colors'

export const ContainerReinvista = styled.div`

  .card-carousel {
    background-color: ${grayscale[100]};
    padding: 24px;
    min-height: 276px;
    border-radius: 16px;

    @media ${device.desktopLG} {
      min-height: 300px;
    }
  }

  .react-multi-carousel-track {
    padding-top: 40px;
  }

  .react-multi-carousel-dot--active {
    button {
      background: ${green[500]};
    }
  }

  .react-multi-carousel-dot-list {
    @media ${device.desktopLG} {
      width: 70%;
    }

    .react-multi-carousel-dot {
      button {
        background: ${green[500]};
      }
    }
  }

  .react-multiple-carousel__arrow {
    svg {
      fill: ${green[500]}
    }
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }
  .react-multiple-carousel__arrow--right {
    @media ${device.desktopLG} {
      right: calc(30% + 1px);
    }
  }
`
