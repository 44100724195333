import React from 'react'
import Img from 'gatsby-image'
import SectionComponent from 'src/components/SectionComponent'
import useWidth from 'src/hooks/window/useWidth'
import usePageQuery from '../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { WIDTH_MD } from 'src/utils/breakpoints'
import { ModalProps } from '../../types'
import { Button, LinkButton } from '../../styles'

const HeroInvestimentoEmpresarial = ({ setIsOpen }: ModalProps) => {
  const data = usePageQuery()
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  const urlAbrirContaPJ = 'https://conta-digital-pj.bancointer.com.br/login'
  const urlAppMobile = 'https://intergo.app/e015cd05'

  const HandleClick = () => {
    width < WIDTH_MD
      ? window.location.href = `${urlAppMobile}`
      : setIsOpen && setIsOpen(true)
  }

  return (
    <SectionComponent
      id='investimento-empresarial'
      minHeight={{ sm: '717px', md: '416px', lg: '504px', xl: '648px)', xxl: '650px' }}
    >
      <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0 order-md-last'>
        { width <= WIDTH_MD
          ? <Img fluid={data.heroEmpreendedoraFelizMobileTablet.fluid} alt='Empreendedora feliz com os resultados de seus investimentos empresariais.' />
          : <Img fluid={data.heroEmpreendedoraFelizDesktop.fluid} className='float-lg-right' alt='Empreendedora feliz com os resultados de seus investimentos empresariais.' />
        }
      </div>
      <div className='col-12 col-md-6 col-lg-7 col-xl-5 pr-lg-5 pr-xl-3'>
        <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 font-sora text-grayscale--500 fw-600'>
          <span className='d-block'>Investimento</span> Empresarial Inter
        </h1>
        <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 mb-4 text-grayscale--500'>
          Os melhores investimentos para empresas estão disponíveis no novo Super App da sua Conta PJ.
        </p>
        <Button
          className='btn my-3 text-none'
          title='Investir'
          onClick={() => {
            HandleClick()
            sendDatalayerEvent({
              section: 'dobra_01',
              section_name: 'Investimento Empresarial: valorize o dinheiro da sua empresa',
              element_action: 'click button',
              element_name: 'Quero investir',
             })
          }}
        >
          Quero investir
        </Button>
        <LinkButton
          href={urlAbrirContaPJ}
          target='_blank'
          className='btn bg-none text-none'
          title='Abrir Conta PJ'
          onClick={() =>
            sendDatalayerEvent({
              section: 'dobra_01',
              section_name: 'Investimento Empresarial: valorize o dinheiro da sua empresa',
              element_action: 'click button',
              element_name: 'Abrir Conta PJ',
              redirect_url: urlAbrirContaPJ,
            })
          }
        >
          Abrir Conta PJ
        </LinkButton>
      </div>
    </SectionComponent>
  )
}

export default HeroInvestimentoEmpresarial
