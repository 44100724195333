import React from 'react'
import Img from 'gatsby-image'
import SectionComponent from 'src/components/SectionComponent'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import usePageQuery from '../../pageQuery'
import pageText from './page-text-blog.json'
import { BlogDataProps } from '../../types'
import { BlogCards } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const Blog = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='blog-investimento-empresarial'
      minHeight={{ sm: '1502px', md: '668px', lg: '823px', xl: '888px', xxl: '920px' }}
    >
      <div className='col-12 col-lg-11 col-xl-9 mx-lg-auto mb-4 px-lg-0'>
        <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500 text-center'>{pageText.title}</h2>
        <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-xl-18 lh-xl-22 text-grayscale--400 text-center mt-4'>
          {pageText.description}
        </p>
      </div>
      {
        pageText.blogContent.map((item: BlogDataProps, index: number) => (
          <BlogCards key={index} className='col-12 col-md-4 p-card mt-md-2 mb-4 mb-md-0'>
            <article className='col-12 px-0'>
              <a
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_07',
                    section_name: 'Fique por dentro dos nossos conteúdos sobre investimento empresarial',
                    element_action: 'click button',
                    element_name: item.title,
                    redirect_url: item.link,
                   })
                }}
                href={item.link} target='_blank' rel='noreferrer'
                title={`Leia mais em ${item.link}`}
                className='d-block'
              >
                <Img fluid={data[item.image].fluid} alt={item.alt} />
                <div className='col-12 pt-3 px-4 px-md-3 px-lg-4 mt-n3 mt-md-n5 bg-white content'>
                  <span className='tag text-white fw-600'>{item.tag}</span>
                  <p className='fs-20 lh-25 fw-600 mt-3 mb-2 mt-md-2 font-sora text-grayscale--500 title'>{item.title}</p>
                  <b className='fs-12 lh-15 text-grayscale--300 d-block pb-1 pb-md-0'>{item.data}</b>
                  <p className='fs-md-16 lh-md-20 mb-2 mt-3 text-grayscale--400 d-none d-lg-block'>{item.description}</p>
                  <div className='d-none d-lg-block'>
                    <span className='fs-14 lh-17 text-orange--extra text-right mt-xl-3'>{pageText.readMore}</span>
                    <OrangeDsIcon size='MD' icon='arrow-right' color='#FF7A00' />
                  </div>
                </div>
              </a>
            </article>
          </BlogCards>
        ))
      }
    </SectionComponent>
  )
}

export default Blog
