import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import FAQ from 'src/components/Faq/index'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { FaqProps } from '../../types'

const FAQComponent = ({ faq }: FaqProps) => {
  const width = useWidth(300)

  return (
    <SectionComponent
      id='perguntas-frequentes'
      SectionStyles={width < WIDTH_MD ? 'bg-grayscale--100' : '' }
      minHeight={{ sm: '503px', md: '644px', lg: '649px', xl: '876px' }}
    >
      <div className='col-12'>
        <h3 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-md-4'>
          Perguntas Frequentes sobre Investimentos PJ no Inter
        </h3>
      </div>
      <FAQ
        answerData={faq}
        columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
        searchBg={width < WIDTH_MD ? '#fff' : '#F5F6FA' }
        color='#07605B'
        chevronBgColor='#E5F2F1'
        chevronColor='#409B97'
      />
    </SectionComponent>
  )
}

export default FAQComponent
